
import React, { useState } from "react"
import Navbar from "../components/navbar";
import SinglePageHeader from "../components/singlePageHeader";
import tick from "../images/verified.svg"
import servicesinner from "../images/serv-inner.jpg"
import Layout from "../components/layout";
import mobiletool1 from "../images/tools/ios.jpg"
import mobiletool2 from "../images/tools/android.jpg"
import mobiletool3 from "../images/tools/xamarin.jpg"
import mobiletool4 from "../images/tools/html.jpg"
import mobiletool5 from "../images/tools/ionic.jpg"
import mobiletool6 from "../images/tools/jquery.jpg"
import Speak from "../components/speak";

import webtool1 from "../images/tools/java.jpg"
import webtool2 from "../images/tools/php.jpg"
import webtool3 from "../images/tools/pythan.jpg"
import webtool4 from "../images/tools/wp.jpg"
import webtool5 from "../images/tools/ms.jpg"
import webtool6 from "../images/tools/aws.jpg"
import webtool7 from "../images/tools/gc.jpg"
import webtool8 from "../images/tools/react.jpg"
import webtool9 from "../images/tools/angular.jpg"
import webtool10 from "../images/tools/node.jpg"
import webtool11 from "../images/tools/mangodb.jpg"
import webtool12 from "../images/tools/codei.jpg"
import webtool13 from "../images/tools/kfkha.jpg"
import webtool14 from "../images/tools/hadoop.jpg"
import webtool15 from "../images/tools/gatspy.jpg"


const ExpertiseSinglePage = ({location}) => {

    const expertise = location.state ? location.state.choice : '';
    console.log("expertise",expertise)
    return (
        <div>
            <Layout change="singleHeader">
            <Navbar location="projectDetails-single"/>
            <SinglePageHeader expertiseType={expertise}/>
                {console.log("expertise inside",expertise)}
            <div  className="singlepage-wrapper">{
                    (expertise && expertise === "mobile-solutions") ?
                     <section className="skew-bg mobile-content draken single-bg" id="mobile-content">
                    <div className="container-lg container-md">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="title-wrap mb-0 pr-4">
                                <div className="section-title margin-bottom-70">
                                    <h2 className="section-title">Mobile Solutions</h2>
                                    <span className="border-bottom"></span>
                                </div>
                                <div className="about-desc">
                                    <p>Every business in the cloud now needs a mobile application and Xminds with lots of experience in mobile application development will help you ‘push the envelope’ for your technological advancement. Xminds, being at the forefront of emerging technology adoption formed a talented pool of highly skilled engineers to build leading apps within tight launch windows.</p>
                                    <p>We have assisted several partners to harness the potential of mobile technology by choosing the ideal framework for platform portability without sacrificing features such as accelerometer, geo-location, and camera.</p>
                                </div>
                            </div>
                        </div>
                                <div className="col-lg-5">
                                <h2 className="sub-titles">Our mobile app solutions include:</h2>
                            <div className="list-item-wrapper">
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Native iOS development</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Native Android development</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Cross platform application development</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Responsive web application development</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Enterprise mobility</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>

                                <div className="row pad-top-60 pad-bot-60 choose-wrapper">
                                    <div className="col-md-12 col-lg-7 choose-content">
                                        <h2 className="sub-titles">Why choose xminds for creating mobile Solutions?</h2>
                                        <div className="list-item-wrapper">
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Uses robust IDE’s to speed up programming</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Skilled engineers in mobile technologies such as Android and iPhone</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Specific UI design expertise for small screens</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Offers innovative data handling techniques given limited device capability</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Has extensive mobile applications implementation experience</div>
                                    </div>
                                            </div>
                                   <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Engineers skilled in performance benchmarking tools</div>
                                    </div>
                                </div>
                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-5 choose-content">
                                            <div className="list-item-title media">
                                                <img className="img-fluid" src={servicesinner} alt="tick" />
                                            </div>
                                        </div>
                                  </div>
                            </div>

                            <div className="tools-wrap">
                                 <div className="container-lg container-md">
                             <div className="row pad-top-60 pad-bot-60">
                                <div className="col-lg-12">
                                <h3 className="sub-titles">Tools & Technologies</h3>
                            <div className="list-item-wrapper tool-container">
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={mobiletool1} alt="ios" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={mobiletool2} alt="Android" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={mobiletool3} alt="Xamarin" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={mobiletool4} alt="HTML5" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={mobiletool5} alt="Ionic" />
                                    </div>
                                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={mobiletool6} alt="Jquery" />
                                    </div>
                                </div>
                            </div>
                        </div>
                                </div>
                                </div>
                                </div>
                </section>:  (expertise && expertise === "web-solutions") ?
                    <section className="skew-bg web-content draken single-bg" id="web-content">
<div className="container-lg container-md">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="title-wrap mb-0 pr-4">
                                <div className="section-title margin-bottom-70">
                                    <h2 className="section-title">Web Solutions</h2>
                                    <span className="border-bottom"></span>
                                </div>
                                <div className="about-desc">
                                    <p>If you are looking to develop low-cost, high-performance web applications, then Xminds services will be right for you. Our expert and dedicated development team, who believe in building state of the art applications, will help build quality web applications for you.  The Xminds team has always been instrumental in exploiting the cross-browser cross-platform technologies to support rich graphics and rich media interactions in the application thereby delivering business owners an edge over their competitors.</p>
                                    <p>We offer the right formula to get applications from concept to existence and ultimately to the viral stage by using innovative business intelligence tools. Our understanding of the digital conversations enables us to consolidate online data so that businesses will get a 360-degree view of their customers and can present them with what they are looking for.</p>
                                </div>
                            </div>
                        </div>
                                <div className="col-lg-5">
                                <h2 className="sub-titles">Our web solutions include:</h2>
                            <div className="list-item-wrapper">
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Custom Web Applications</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Ecommerce</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Enterprise Systems</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc"> Content Management Systems</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Website Development</div>
                                    </div>
                                                </div>
                                   <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Rich Internet Applications</div>
                                    </div>
                                                </div>
                                   <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Big Data Service & Predictive Analytics</div>
                                    </div>
                                                </div>
                                   <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Product Development</div>
                                    </div>
                                                </div>
                                   <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Minimum Viable Product (MVP) Development</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                                        <div className="row pad-top-60  pad-bot-60 choose-wrapper">
                                            <div className="col-lg-7 col-md-12 choose-content">
                                        <h2 className="sub-titles">Why choose xminds for creating web solutions?</h2>
                                        <div className="list-item-wrapper">
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">State of the art development platforms & tools
</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Domain knowledge & domain related investments at the start of each project</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Mature & continuously improving design and programming techniques</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc"> Continuous & complete project management working in concert with the client
</div>
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img className="img-cricles" src={tick} alt="tick" />
                                        <div className="media-body list-item-desc">Expert core committee to preview design & programming activities
</div>
                                    </div>
                                            </div>
                            </div>
                                            </div>
                                            <div className="col-md-12 col-lg-5 choose-content">
                                                <div className="list-item-title media">
                                                    <img className="img-fluid" src={servicesinner} alt="tick" />
                                                </div>
                                            </div>
                                    </div>

                                </div>
                                <div className="tools-wrap">
                                 <div className="container-lg container-md">
                             <div className="row pad-top-60 pad-bot-60">
                                <div className="col-lg-12">
                                <h3 className="sub-titles">Tools & Technologies</h3>
                            <div className="list-item-wrapper tool-container">
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool1} alt="java" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={webtool2} alt="php" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={webtool3} alt="Python" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={webtool4} alt="WordPress" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={webtool5} alt="Microsoftdotnet" />
                                    </div>
                                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                       <img src={webtool6} alt="AWS" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool7} alt="Googlecloud" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool8} alt="React" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool9} alt="Angular" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool10} alt="Node" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool11} alt="MongoDB" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool12} alt="Codeigniter" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool13} alt="Kafka" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool14} alt="Hadoop" />
                                    </div>
                                </div>
                                <div className="list-item-inner mb-3">
                                    <div className="list-item-title media">
                                         <img src={webtool15} alt="Gatsby" />
                                    </div>
                                </div>
                            </div>
                        </div>
                                </div>
                                </div>
                                </div>
                </section> : (expertise && expertise === "cloud-solutions") ?
                     <section className="skew-bg cloud-content draken single-bg" id="cloud-content">
                         <div className="container-lg container-md">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title-wrap mb-0 pr-4">
                                <div className="section-title margin-bottom-70">
                                    <h2 className="section-title">Cloud Solutions</h2>
                                    <span className="border-bottom"></span>
                                </div>
                                <div className="about-desc">
                                    <p>Cloud services and virtualization are changing the way enterprises manage their information technology infrastructure. This interest is driven by a desire to significantly improve business and IT agility, and lower IT costs at the same time. Only option to achieve both these goals simultaneously is to offshore part of IT infrastructure management to an organization that understands that world better.</p>
                                    <p>Xminds’ server team has immense experience working on several cloud platforms and we offer services in public, private and hybrid networks. The solutions include a detailed approach to understanding business goals and objectives while aligning technical capabilities that constitute the business workload.</p>
                                </div>
                            </div>
                        </div>

                            </div>
                                            <div className="row pad-top-60 pad-bot-60 choose-wrapper">
                                <div className="col-lg-12">
                                <h2 className="sub-titles">a) Amazon Web Services:</h2>
                                <div className="about-desc">
                                    <p>The AWS is implemented as server farms or clusters of servers or data centres where the data is saved and these are spread across the world so as to keep the integrity of the data. The AWS provides a host of services ranging from computing, data storage, networking, database, analytics, application services, deployment, management, mobile, developer tools and in some cases for the internet of things.</p>
                                    <p>Among the most popular AWS services include Amazon Elastic Compute Cloud (EC2) and Amazon Simple Storage Service (S3). These are accessed over the HTTP, using the REST architectural style and SOAP protocol. AWS in 2016 was said offering more than 70 services. To name a few popular ones are, CloudDrive, Dynamo Database (also known as DynamoDB or DDB), Elastic Compute Cloud, ElastiCache, Mechanical Turk, RedShift, Simple Storage Service (S3).</p>
                                </div>
                                            </div>
                                                <div className="col-lg-12 pad-top-60 choose-wrapper">
                                <h2 className="sub-titles">b) Google platform:</h2>
                                <div className="about-desc">
                                    <p><strong>Google Compute Engine:</strong> It is the Infrastructure as a Service (IaaS) component of Google Cloud Platform which is built on the global infrastructure that runs Google’s search engine, Gmail, YouTube and other services. Google Compute Engine enables users to launch virtual machines (VMs) on demand. VMs can be launched from the standard images or custom images created by users. GCE users need to get authenticated based on OAuth 2.0 before launching the VMs.</p>
                                    <p><strong>Google App Engine:</strong> It is the Platform as a Service (PaaS) component of  Google Cloud Platform which is a platform for developing and hosting web applications in Google-managed data centres. Applications are sandboxed and run across multiple servers.They offer automatic scaling for web applications, as the number of requests increases for an application, App Engine automatically allocates more resources for the web application to handle the additional demand. It is free up to a certain level of consumed resources. Fees are charged for additional storage, bandwidth, or instance hours required by the application.</p>
                                    <p><strong>Bigtable:</strong> The Bigtable is a compressed high performance data storage system that is proprietary to Google. This Filesystem is created based on the Google File System and Google’s Chubby Lock Service, SSTable and a few other google technologies. Bigtable also underlies Google Cloud Datastore, which is available as a part of the Google Cloud Platform.</p>
                                    <p><strong>BigQuery:</strong> It is a RESTful Web Service that enable the users to analyse massively large data sets along with the Google storage. Its is an Infrastructure as a service that can used complementarily with MapReduce.</p>
                                </div>
                                </div>
                                </div>
                    </div>
                </section>: (expertise && expertise === "qa-solutions") ?
                    <section className="skew-bg qa-content draken single-bg" id="qa-content">
<div className="container-lg container-md">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title-wrap mb-0 pr-4">
                                <div className="section-title margin-bottom-70">
                                    <h2 className="section-title">QA / Software Testing</h2>
                                    <span className="border-bottom"></span>
                                </div>
                                <div className="about-desc">
                                    <p>At present every web/online businesses are on their toes to make sure that they have an edge over their competitors. To stay amongst this fierce competition online/web business needs to keep changing and adding more features rapidly to their software product.
And this, in turn, has changed the way new feature updates and releases are done. These applications operate in a very dynamic environment with rapid release cycle modes.</p>
                                    <p>This is where software testing comes into play ensuring that the application is reliable and able to operate consistently under peak loads. The QA team will also make sure the potential release version meets all the functional and performance requirements before certifying the particular release.
                                    Our independent testing facility is manned by Test Engineers with 7 + years of experience and our team uses efficient and state-of-the-art defect management tools.</p>
                                </div>
                            </div>
                        </div>

                            </div>
                                <div className="row pad-top-60 choose-wrapper ">
                                     <div className="col-lg-12"><h2 className="sub-titles">Our services include:</h2></div>
                                <div className="col-lg-12">
                                <h2 className="sub-titles">a) Usability Testing:</h2>
                                <div className="about-desc">
                                    <p>Usability Testing is performed to check user friendliness and intuitiveness of the application. The main goal is to find out where users might get distracted and wander off to a different site. Our team here at Xminds ensures that the application is easy-to-use, user-friendly application that provides a positive customer experience.</p>
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <h2 className="sub-titles">b) Regression Testing (Automated):</h2>
                                <div className="about-desc">
                                    <p>The objective of regression testing is to ensure that the product remains functional across previous versions and it also verifies that the changes introduced during the new release have not introduced any bugs. This mode of testing is imperative when there are continuous improvements done in the software application. Our team at Xminds creates the test plan and runs automated test cases on a daily basis to avoid last minute surprises.</p>
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <h2 className="sub-titles">c) Functional Testing:</h2>
                                <div className="about-desc">
                                    <p>Functional testing ensures that the application complies with all the required functionality mentioned in the requirement specifications. The team at Xminds validates the behavior of the software application and ensures that the features of the application are in line with the requirements provided by the client.</p>
                                </div>
                                </div>
                                </div>
                    </div>
                </section> :
                     <div>
                     </div>
                }
                {
                    (expertise) ?
                        <Speak/> : null
                }
                </div>

                </Layout>

            </div>


    )
}


export default ExpertiseSinglePage